<template lang="pug">
  div
    v-autocomplete(
      v-model="tags"
      :items="$store.state.settings.tagSettings.tag_data"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      label="Select Tags"
      placeholder="Start typing to Search"
      multiple
      chips
      @change="onChange"
    )
      template(slot="selection" slot-scope="data")
        v-chip.chip--select-multi(:selected="data.selected" close @input="onRemove(data.item.id)")
          | {{data.item.name}}
  
</template>
<script>
export default {
  props: ["value", "multiple"],
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    tags: null,
    search: null
  }),

  methods: {
    onChange: function(theValue) {
      this.$emit("input", theValue);
    },

    onRemove: function(item) {
      const theIndex = this.tags.findIndex(value => value === item);
      this.tags.splice(theIndex, 1);
    }
  },

  mounted() {
    this.tags = this.value;
    if (this.$store.state.settings.tagSettings.currentIndex === -1) {
      this.isLoading = true;
      this.$store.dispatch("settings/tagSettings/loadAllTags").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style scoped lang="stylus"></style>

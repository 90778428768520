<template lang="pug">
  .fill-width.pa-2()
    v-toolbar(dense flat)
      v-toolbar-title Settings
      v-spacer
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='edit') Edit Template
      v-btn.mb-2.mx-2(small, color='primary',outlined, dark, @click='cancel') Cancel
      v-btn.mb-2.mx-2(small, color='primary', dark, @click='save') Save

    .settingPadding()
      form-divider.mb-2 Inspection
      v-form(v-model="valid" ref="form")
        v-row()
          v-col(cols="12" xs="12" md="6" xl="4")
            v-text-field(v-model='name' label='Form Name')
          v-col(cols="12" xs="12" md="6" xl="4")
            v-text-field(v-model='description' label='Form Description')
          v-col(cols="12" xs="12" md="12" xl="12")
            attach-fill-form(:schema="imageSchemas" :data="{ value:thumbnail_id}" @onChange="saveInspectionImage"  @onMenuAction="onMenuAction")

        form-divider.mb-2 Delete Template
        v-row()
          v-col(cols="12" xs="12" md="6" xl="6")
            v-btn.my-2(color="primary" @click="deleteTemplate()" ) Delete Template

</template>
<script>
import FormSubHeader from "@/modules/global/components/forms/FormSubHeader.vue";
import FormDivider from "@/modules/global/components/forms/FormDivider.vue";
import AttachFillForm from "@/modules/global/components/forms/AttachFillForm.vue";
import {createHelpers} from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "inspection/inspectionForm/getInspectionForm",
  mutationType: "inspection/inspectionForm/updateInspectionForm"
});
export default {
  components:{
    FormSubHeader,
    FormDivider,
    AttachFillForm
  },
  data: ()=>({
    valid: false,

    imageSchemas:{
      name: "Attach",
      id: "12345",
      value: [],
      label: "INSPECTION THUMBNAIL",
      hint: "jpeg or png",
      type: "image",
      image_size: "small",
      multiple: false
    }
  }),

  computed: {
    ...mapFields({
      name: "name",
      description: "description",
      thumbnail_id: "thumbnail_id",
      title: "details.settings.title",
      sub_title1:"details.settings.sub_title1",
      sub_title2:"details.settings.sub_title2",
      avatar:"details.settings.avatar",
      showAuthor:"details.settings.show_author",
      showDate:"details.settings.show_date",
    }),


  },

  methods: {
    edit() {
      let item = this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"];
      this.$store.commit("inspection/formDesigner/resetFormBuilder");
      this.$store.commit(
        "inspection/inspectionForm/setCurrentIndexByID",
        item.id
      );
      // save the latest scheme
      this.$store.commit(
        "inspection/formDesigner/setSchema",
        this._.clone(
          item.data[item.data.length - 1].schema
        )
      );
      this.$router.push({
        name: "inspectionFormDesigner",
        params: { inspectionID: item.id }
      }).catch(()=>{});
    },

    cancel() {
      this.$router.push({name: "inspections", params: {tabID:1}}).catch(()=>{});
    },
    save() {
      this.$store.dispatch("inspection/inspectionForm/saveInspectionForm").then(() => {
        this.$store.commit("showSnackBar", {
          color: "success",
          message: "<B>Saved Inspections Settings</B>"
        });
        // this.$router.push({ name: "workflows" }).catch(()=>{});
        this.$router.push({name: "inspections", params: {tabID:1}}).catch(()=>{});
      })
    },
    saveInspectionImage(theObject) {
      this.$store.commit("inspection/inspectionForm/updateInspectionForm", {
        path: "thumbnail_id",
        value: theObject.value[0]
      });
    },

    deleteTemplate: function () {
      let  inspectionForm = this.$store.getters["inspection/inspectionForm/getCurrentInspectionForm"];
      confirm(
        "Are you sure you want to delete the template? " +
        inspectionForm.name
      ) &&
      this.$store.dispatch("inspection/inspectionForm/deleteInspectionForm", inspectionForm.id).then(() => {
        this.$router.push({name: "inspections", params: {tabID:1}}).catch(()=>{});
      });
    },


    onMenuAction: function (value) {
      // console.log(value);
      switch (value.action) {
        case "delete":
          // this.$store.commit("settings/accountSettings/updateAccountSetting", {
          //   path: "thumbnail",
          //   value: null
          // });

          break;
        case "edit":
          console.log("edit");
          break;
        case "make_thumbnail":
          console.log("make_default");
          break;
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.settingPadding{
  padding 0px 10px;
}
.boardCardSample{
  margin auto;
  cursor: default;
  min-width: 270px;
}
.boardAvatar{
  width 80px;
  height 80px;
  border-radius 10px;
  background-color #330033;
}
.smallWorkflowAvartar{
  font-size 12px;
  color white;
  font-weight bold
}

.mapLayout{
  height 400px;
  width 200px;
  background-repeat no-repeat;
  background-size contain;
}
</style>